import casting_calls_services from '@/services/casting_calls';
import proposals_services from '@/services/proposals';
import { getUserData } from '@/libs/utils/user'

export default [
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read'
    }
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/pages/authentication/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true
    }
  },
  {
    path: '/mapas',
    name: 'mapas',
    component: () => import('@/views/pages/outofhome/Index.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    }
  },
  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/pages/authentication/Register.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true
    }
  },
  {
    path: '/account-company',
    name: 'account-company',
    component: () => import('@/views/pages/authentication/AcountCompany.vue'),
    meta: {
      resource: 'Auth',
      action: 'read'
    }
  },
  {
    path: '/additional-information',
    name: 'additional-information',
    component: () => import('@/views/pages/authentication/AcountCompany.vue'),
    meta: {
      resource: 'Auth',
      show_secunday_navbar: false,
      action: 'read'
    }
  },
  {
    path: '/adios',
    name: 'good-bye',
    component: () => import('@/views/pages/account-setting/GoodBye.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true
    }
  },
  {
    path: '/unsubscribe',
    name: 'unsubscribe',
    component: () => import('@/views/pages/account-setting/Unsubscribe.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth'
    }
  },
  /*{
    path: '/pages/authentication/login-v1',
    name: 'auth-login-v1',
    component: () => import('@/views/pages/authentication/Login-v1.vue'),
    meta: {
      layout: 'full'
    }
  },
  {
    path: '/pages/authentication/login-v2',
    name: 'auth-login-v2',
    component: () => import('@/views/pages/authentication/Login-v2.vue'),
    meta: {
      layout: 'full'
    }
  },*/
  {
    path: '/pages/authentication/register-v1',
    name: 'auth-register-v1',
    component: () => import('@/views/pages/authentication/Register-v1.vue'),
    meta: {
      layout: 'full'
    }
  },
  {
    path: '/invitation/:uuidInfluencer/:uuid',
    name: 'invitation',
    component: () => import('@/views/pages/invitation/invitation.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth'
    }
  },
  {
    path: '/invitationDetails/:uuidInfluencer/:uuid',
    name: 'invitationDetails',
    component: () => import('@/views/pages/invitation/invitationDetails.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth'
    }
  },
  {
    path: '/external/:slug/:network/:username',
    name: 'invitation-external',
    component: () => import('@/views/pages/invitation/invitation.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth'
    }
  },
  {
    path: '/external/details/:slug/:network/:username',
    name: 'new-invitation-external',
    component: () => import('@/views/pages/invitation/invitationDetails.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth'
    }
  },
  {
    path: '/external-new/details/:slug/:network/:username',
    name: 'new-detail-invitation-external',
    component: () => import('@/views/pages/invitation/newInvitationDetail.vue'),
    meta: {
      resource: 'Auth'
    }
  },
  {
    path: '/pages/authentication/register-v2',
    name: 'auth-register-v2',
    component: () => import('@/views/pages/authentication/Register-v2.vue'),
    meta: {
      layout: 'full'
    }
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/pages/authentication/ForgotPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true
    }
  },
  {
    path: '/pages/authentication/forgot-password-v1',
    name: 'auth-forgot-password-v1',
    component: () => import('@/views/pages/authentication/ForgotPassword-v1.vue'),
    meta: {
      layout: 'full'
    }
  },
  {
    path: '/pages/authentication/forgot-password-v2',
    name: 'auth-forgot-password-v2',
    component: () => import('@/views/pages/authentication/ForgotPassword-v2.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth'
    }
  },
  {
    path: '/pages/authentication/reset-password-v1',
    name: 'auth-reset-password-v1',
    component: () => import('@/views/pages/authentication/ResetPassword-v1.vue'),
    meta: {
      layout: 'full'
    }
  },
  {
    path: '/pages/authentication/reset-password-v2',
    name: 'auth-reset-password-v2',
    component: () => import('@/views/pages/authentication/ResetPassword-v2.vue'),
    meta: {
      layout: 'full'
    }
  },
  {
    path: '/pages/miscellaneous/coming-soon',
    name: 'misc-coming-soon',
    component: () => import('@/views/pages/miscellaneous/ComingSoon.vue'),
    meta: {
      layout: 'full'
    }
  },
  {
    path: '/pages/miscellaneous/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/views/pages/miscellaneous/NotAuthorized.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth'
    }
  },
  {
    path: '/pages/miscellaneous/under-maintenance',
    name: 'misc-under-maintenance',
    component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
    meta: {
      layout: 'full'
    }
  },
  {
    path: '/pages/miscellaneous/error',
    name: 'misc-error',
    component: () => import('@/views/pages/miscellaneous/Error.vue'),
    meta: {
      layout: 'full'
    }
  },
  {
    path: '/pages/account-setting/:section/',
    name: 'pages-account-setting',
    component: () => import('@/views/pages/account-setting/AccountSetting.vue'),
    meta: {
      resource: 'ACL',
      action: 'read'
    }
  },
  {
    path: '/pages/account-setting/',
    name: 'pages-account-setting-without-section',
    component: () => import('@/views/pages/account-setting/AccountSetting.vue'),
    meta: {
      resource: 'ACL',
      action: 'read'
    }
  },
  {
    path: '/leads',
    name: 'leads',
    component: () => import('@/views/pages/leads/Leads.vue'),
    meta: {
      resource: 'ACL',
      action: 'read'
    }
  },
  {
    path: '/my-work/:section',
    name: 'my_work-without-section',
    component: () => import('@/views/pages/proposals/myWork.vue'),
    meta: {
      resource: 'ACL',
      action: 'read'
    }
  },
  {
    path: '/graphics/',
    name: 'graphics',
    component: () => import('@/views/pages/profile/graphics.vue'),
    meta: {
      resource: 'Auth'
    }
  },
  {
    path: '/home-search',
    name: 'home_search',
    component: () => import('@/views/pages/proposals/homeSearch.vue'),
    meta: {
      resource: 'Auth'
    }
  },
  {
    path: '/search-proposal/:query?',
    name: 'search_proposal',
    component: () => import('@/views/pages/proposals/searchProposal.vue'),
    meta: {
      resource: 'Auth'
    }
  },
  {
    path: '/casting-calls',
    name: 'casting_calls',
    component: () => import('@/views/pages/casting-calls/castingCalls.vue'),
    meta: {
      resource: 'Auth'
    }
  },
  {
    path: '/my-work/:section?/',
    name: 'my_work',
    component: () => import('@/views/pages/proposals/myWork.vue'),
    meta: {
      resource: 'ACL',
      action: 'read'
    }
  },
  {
    path: '/@:user',
    name: 'user_profile',
    component: () => import('@/views/pages/proposals/profileNetworks.vue'),
    meta: {
      resource: 'Auth'
    }
  },
  {
    path: '/connection/@:user/:network/:username',
    name: 'detail_connection',
    component: () => import('@/views/pages/proposals/detail-profile-analytics/detailConnection.vue'),
    meta: {
      resource: 'ACL',
      action: 'read'
    }
  },
  {
    path: '/step-proposal/:step/:slug?/',
    name: 'steps_proposal',
    component: () => import('@/views/pages/proposals/stepper/dashboard.vue'),
    meta: {
      resource: 'ACL',
      action: 'read'
    },
    beforeEnter: (to, from, next) => {
      const slug = to.params.slug;
      if (slug) {
        proposals_services.detailProposal(slug).then((response) => {
          if ((getUserData().id === response.user_network.user)) {
            to.params.proposal = response;
            next()
          } else {
            next({ name: 'error-404' });
          }
        }).catch(() => {
          next({ name: 'error-404' });
        });
      } else next()
    },
  },
  {
    path: '/view-proposal/:slug',
    name: 'view_proposal',
    component: () => import('@/views/pages/proposals/stepper/preview.vue'),
    meta: {
      resource: 'Auth'
    },
    beforeEnter: (to, from, next) => {
      const slug = to.params.slug;
      proposals_services.detailProposal(slug).then((response) => {
        if ((response.state !== 'published' && getUserData().id !== response.user_network.user) && !getUserData().is_superuser) {
          const has_permission_edit = getUserData().user_permissions.find(per => per.codename === 'change_proposalsusernetwork')
          if (!has_permission_edit) next({ name: 'error-404' });

          to.params.proposal = response;
          next();
        } else {
          to.params.proposal = response;
          next();
        }
      }).catch(() => {
        next({ name: 'error-404' });
      });
    },
  },
  {
    path: '/view-casting-call/:slug/',
    name: 'view_casting_call',
    component: () => import('@/views/pages/casting-calls/previewCasting.vue'),
    meta: {
      resource: 'Auth'
    },
    beforeEnter: (to, from, next) => {
      const slug = to.params.slug;
      casting_calls_services.getCastingCallBySlug(slug).then((response) => {
        if ((response.status !== 'published' && getUserData().id !== response.user) && !getUserData().is_superuser) {
          next({ name: 'error-404' });
        } else {
          to.params.casting_call_data = response;
          next();
        }
      }).catch(() => {
        next({ name: 'error-404' });
      });
    },
  },
  {
    path: '/pages/profile',
    name: 'pages-profile',
    component: () => import('@/views/pages/profile/Profile.vue'),
    meta: {
      pageTitle: 'Profile',
      breadcrumb: [
        {
          text: 'Pages'
        },
        {
          text: 'Profile',
          active: true
        }
      ]
    }
  },

  {
    path: '/monitor',
    name: 'pages-knowledge-base',
    component: () => import('@/views/pages/Knowledge-base/KnowledgeBase.vue'),
  },
  {
    path: '/pages/knowledge-base/:category',
    name: 'pages-knowledge-base-category',
    component: () => import('@/views/pages/Knowledge-base/KnowledgeBaseCategory.vue'),
    meta: {
      pageTitle: 'Category',
      breadcrumb: [
        {
          text: 'Pages'
        },
        {
          text: 'Knowledge Base',
          to: '/pages/Knowledge-base'
        },
        {
          text: 'Category',
          active: true
        }
      ],
      navActiveLink: 'pages-knowledge-base'
    }
  },
  {
    path: '/pages/knowledge-base/:category/:slug',
    name: 'pages-knowledge-base-question',
    component: () => import('@/views/pages/Knowledge-base/KnowledgeBaseCategoryQuestion.vue'),
    meta: {
      pageTitle: 'Question',
      breadcrumb: [
        {
          text: 'Pages'
        },
        {
          text: 'Knowledge Base',
          to: '/pages/Knowledge-base'
        },
        {
          text: 'Category',
          to: '/pages/Knowledge-base/category'
        },
        {
          text: 'Question',
          active: true
        }
      ],
      navActiveLink: 'pages-knowledge-base'
    }
  },
]
