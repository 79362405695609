import Vue from 'vue'
import { ToastPlugin, ModalPlugin, IconsPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'

// Install the Plugin.
//Vue.use(VeeValidate)

// Vuesax Component Framework
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css' // Vuesax
Vue.use(Vuesax)

//Portal vue
import PortalVue from 'portal-vue'
Vue.use(PortalVue)

//VueScroll
import vuescroll from 'vuescroll';
Vue.use(vuescroll)

//VueClipboaard
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)


// Localize takes the locale object as the second argument (optional) and merges it.
//Validator.localize('es', es)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(IconsPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

// import darkmode styles
require('@/assets/css/stylesDarkMode.scss');

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
