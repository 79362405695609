<template>
  <div
    id="app"
    class="h-100"
    :class="`${[skinClasses]} ${to_dark_mode}`"
  >
    <component :is="layout">
      <router-view />
    </component>

    <scroll-to-top v-if="enableScrollToTop" />

    <b-modal hide-footer hide-header v-model="open_modal_notifications" centered size="sm">
      <div class="p-1 d-flex flex-column align-items-center">
        <feather-icon icon="BellIcon" class="text-primary mb-2" size="60"></feather-icon>
        <h2>{{ $t('creator.active_notification') }}</h2>
        <p class="text-center">{{ $t('creator.text_enable') }}</p>
        <div>

          <b-button class="blue-button" variant="blue-button" @click="requestToken()">{{ $t('creator.btn_enable') }}</b-button>
          <b-button variant="light" class="ml-2" @click="rejectNotifications()">{{ $t('creator.btn_Not_now') }}</b-button>
        </div>
        <span class="d-flex mt-1 justify-content-center align-items-center">
          {{ $t('creator.no_message') }}
          <BFormCheckbox v-model="show_message" class="ml-1"></BFormCheckbox>
        </span>

      </div>
    </b-modal>
  </div>
</template>
<script src="@/assets/js/firebase-messaging-sw.js"></script>

<script>
import {
  BModal,
  BButton,
  BFormCheckbox,
} from 'bootstrap-vue';
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import utils from '@/libs/utils';
import { useWindowSize, useCssVar } from '@vueuse/core'
import { initializeApp } from "firebase/app";
import { getToken, getMessaging, onMessage } from "firebase/messaging";
import { eventClosed } from '@/libs/utils/hubspot';
import notification_service from '@/services/notifications'
import abilities from '@/libs/acl/abilities';
import { updateNotificationUserData } from '@/libs/utils/user';
import store from '@/store'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {

    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,

    ScrollToTop,
    BModal,
    BButton,
    BFormCheckbox,
  },
  data() {
    return {
      to_dark_mode: utils.getSkinMode().value === 'dark' ? 'to-dark-mode' : '',
      open_modal_notifications: false,
      show_message: false,
      is_realty: utils.getUserData() && utils.getUserData().main_group && utils.getUserData().main_group.name === 'realty' ? 'realty' : '',
      interval_reconnect: null,
      attempts: 0
    }
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
  },
  created() {
    const script_chat = document.createElement('script');
    script_chat.src = "//js.hs-scripts.com/2325482.js";
    script_chat.id = 'hs-script-loader';
    document.head.append(script_chat);

    this.$root.$on('connect_ws', () => {
      this.connect();
    });

    script_chat.onload = function() {
      setTimeout(() => {
        window.HubSpotConversations.on('conversationClosed', () => {
          window.HubSpotConversations.on('widgetClosed', eventClosed);
        });

        window.HubSpotConversations.on('unreadConversationCountChanged', event => {
          if (event.unreadCount > 0) window.HubSpotConversations.off('widgetClosed', eventClosed);
        });

        window.HubSpotConversations.on('conversationStarted', () => {
          window.HubSpotConversations.off('widgetClosed', eventClosed);
        });

        window.HubSpotConversations.widget.remove();
      }, 1000);
    };
    const user = utils.getUserData()

    if (user) {
      this.connect();
    }
  },
  methods: {
    connect() {
      const user = utils.getUserData()
      const url_ws = `${process.env.VUE_APP_WS_URL}/ws/socket-server/${user.id}/`;

      const socket = new WebSocket(url_ws);
      socket.onopen = () => {
        console.log('Connected!!!');
      };

      socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.type) {
          if (data.type === 'chat') {
            this.$root.$emit('add_push', data)
            
            if (this.$route.name === 'chat-proposal') {
              const params = this.$route.params
              if (Object.keys(params) === 0 || ('chat_uuid' in params && params.chat_uuid !== undefined)) this.$root.$emit('new_message', data)
            }
          } else if (data.type === 'chat_viewed') {
            this.$root.$emit('chat_viewed', data)
          } else if (data.type === 'notification') {
            this.$root.$emit('add_push', data)
          } else if (data.type === 'donwload_examples') {
            this.$root.$emit('donwload_examples', data)
          } else if (data.type === 'permissions_changes') {
            this.readAbility = abilities.getAbilitie(data.body.detail.user)
            utils.setPermissions(data.body.detail.user);
            this.$ability.update(this.readAbility)
            user.ability = this.readAbility
            localStorage.setItem('userData', JSON.stringify(user))
          } else if (data.type === 'images_created' || data.type === 'variant_images_created') {
            this.$root.$emit('images_created_ws_or_variant', data)
          } else if (data.type === 'chatgpt_response') {
            this.$root.$emit('chatgpt_response_ws', data)
          } else if (data.type === 'video_talk_created') {
            this.$root.$emit('video_talk_created_ws', data)
          } else if (data.type === 'title_proposal' || data.type === 'description_created') {
            this.$root.$emit('proposals_ws_ia', data)
          } else if (data.type === 'chat_active') {
            this.$root.$emit('chat_active', data)
          }
        }
      };

      socket.onclose = () => {
        this.attempts += 1;
        if (this.interval_reconnect) clearInterval(this.interval_reconnect)
        if (this.attempts < 120) {
          this.interval_reconnect = setInterval(() => {
            this.connect();
          }, 1750);
        }
      };

      socket.onerror = () => {
        this.attempts += 1;
        if (this.interval_reconnect) clearInterval(this.interval_reconnect)
        if (this.attempts < 120) {
          this.interval_reconnect = setInterval(() => {
            this.connect();
          }, 1750);
        }
      };
    },
    rejectNotifications() {
      if (this.show_message) {
        notification_service.updateSettingsNotifications({enabled_push: 'ask_push'}).then((response) => {
          updateNotificationUserData(response);
        })
      } else localStorage.setItem('ask_push', 'show_modal_later');
      this.open_modal_notifications = false;
    },
    requestToken() {
      getToken(this.messaging, { vapidKey: process.env.VUE_APP_FIREBASE_VAPID }).then((currentToken) => {
        if (currentToken) {
          console.log(currentToken);
          this.open_modal_notifications = false;
          notification_service.save_token(currentToken).then(response => {
            utils.setPermissions(response.user);
            this.readAbility = abilities.getAbilitie(response.user) 
            utils.setuserData(response, this.readAbility)
          })
        } else {
          console.log('Cayo acá!!!');
          // this.open_modal_notifications = true;
        }
      }).catch((err) => {
        console.log('Error al obtener el token de registro');
        console.log(err);
      });
    },
  },

  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
    watch(utils.getSkinMode(), value => {
      if (value === 'dark') this.to_dark_mode = 'to-dark-mode';
      else this.to_dark_mode = '';
    });
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()
    const { enableScrollToTop } = $themeConfig.layout

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    const firebaseConfig = {
      apiKey: process.env.VUE_APP_FIREBASE_KEY,
      authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.VUE_APP_FIREBASE_PRODUCT_ID,
      storageBucket: process.env.VUE_APP_FIREBASE_BUCKET,
      messagingSenderId: process.env.VUE_APP_FIREBASE_SENDER,
      appId: process.env.VUE_APP_FIREBASE_APP_ID
    }

    const firebase = initializeApp(firebaseConfig)
    const messaging = getMessaging(firebase)

    return {
      skinClasses,
      enableScrollToTop,
      firebase,
      messaging
    }
  },
}
</script>

<style>
@import 'assets/css/style.css';
@import 'assets/css/flag.css';
@import 'assets/css/iconProfile.css';
@import 'assets/css/other_network.css';
@import 'assets/css/search.css';
</style>
