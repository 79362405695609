import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import apps from './routes/apps'
import dashboard from './routes/dashboard'
import pages from './routes/pages'
import others from './routes/others'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'dashboard-analytics' } },
    ...apps,
    ...dashboard,
    ...pages,
    ...others,
    {
      path: '*',
      redirect: 'error-404'
    }
  ]
})

router.afterEach((to) => {
  const max_routes = 30;
  let array_routes = [];
  if (localStorage.getItem('routes_visited')) array_routes = JSON.parse(localStorage.getItem('routes_visited'));
  if ((array_routes[array_routes.length - 1] !== to.name) && !to.name.includes('error-404') && !to.name.includes('step') && !to.name.includes('preview') && !to.name.includes('setting-public-profile') && !to.name.includes('view')) array_routes.push({name: to.name, params: to.params});
  if (array_routes.length > max_routes) array_routes = array_routes.slice(-max_routes);
  localStorage.setItem('routes_visited', JSON.stringify(array_routes));
});
router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  if (to.path === '/roll' && JSON.parse(localStorage.userData).groups.length > 0) {
    next({ name: 'dashboard-analytics' });
  }
  if (to.path === '/pages/account-setting/General' && JSON.parse(localStorage.userData).groups.length === 0) {
    return next({ name: 'auth-roll' })
  }
  if (to.path === '/roll' && _.path === '/register') {
    return next()
  }

  if (to.path === '/dashboard' && isLoggedIn) {
    const user = JSON.parse(localStorage.userData)
    const role = user.main_group ? user.main_group.name : '';
    if (role === 'realty') next({ name: 'campaigns' })
  }
  if (to.path.split('/')[1] === 'perfil') {
    const has_session = localStorage.getItem('accessToken') !== null;
    if (!has_session) return next({ name: 'auth-perfil-public' , params: {network: to.params.network, alias: to.params.alias}})
  }

  if (to.path === '/monitor' && isLoggedIn) {
    const user = JSON.parse(localStorage.userData)
    if (!user.is_superuser) next({ name: 'dashboard-analytics' })
  }
  if (to.name === 'preview_casting_call' && !isLoggedIn) {
    next({ name: 'dashboard-analytics' })
  }

  if (to.path === '/search') {
    const user = JSON.parse(localStorage.userData)
    const role = user.main_group && user.main_group.name ? user.main_group.name : '';
    if (role === 'realty') next({ name: 'realty-search' })
  }

  if (to.path === '/tracking') {
    const user = JSON.parse(localStorage.userData)
    const current_plan = user.current_plan
    if (!current_plan.view_tracking_links) return next({ name: 'misc-not-authorized' })
  }

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData))
  }

  return next()
})

export default router
