export const getUserData = () => {
  return JSON.parse(localStorage.getItem('userData'))
};
export const hasPermission = (permission) => {
  if (localStorage.getItem('permissions') === null) localStorage.setItem('permissions', JSON.stringify([]))
  return JSON.parse(localStorage.getItem('permissions')).indexOf(permission) !== -1;
};
export const setPermissions = (user) => {
  const permissions = user.all_permissions.map(permission => permission.codename)
  localStorage.setItem('permissions', JSON.stringify(permissions))
}
export const setuserData = (response, abilities) => {
  const user = response.user

  const userData = {
    account_company: response.account_company,
    email: user.email,
    first_name: user.first_name,
    groups: user.groups,
    id: user.id,
    is_active: user.is_active,
    is_verified: user.is_verified ? user.is_verified : false,
    is_superuser: user.is_superuser ? user.is_superuser : false,
    last_name: user.last_name,
    main_group: user.main_group,
    max_views: user.max_views,
    user_permissions: user.user_permissions,
    username: user.username,
    ability: abilities,
    profile_image: user.profile_image,
    question_text: user.question_text,
    has_payment_data: user.has_payment_data,
    payment_data: user.has_payment_data ? user.payment_data : null,
    current_plan: user.current_plan,
    meta_info: user.meta_info,
    notification_settings: user.notification_settings
  }
  localStorage.setItem('accept_tyc', user.accept_tyc ? user.accept_tyc : false);
  localStorage.setItem('userData', JSON.stringify(userData))
};
export const updateNotificationUserData = (notification) => {
  const user = JSON.parse(localStorage.getItem('userData'));
  user.notification_settings = notification;
  localStorage.setItem('userData', JSON.stringify(user));
};